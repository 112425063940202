import React, { useState, useEffect } from "react";

import "./index.css";
import { BodyBold2, BodyRegular2 } from "../../styled/Typography";
import { Colors } from "../../../constants";

const CountdownTimer = ({
  timerCount = 30,
  label = "",
  onClick = () => {},
}) => {
  const [timeLeft, setTimeLeft] = useState(timerCount);
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    if (!timerActive) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up interval on unmount or when timerActive becomes false
    return () => clearInterval(intervalId);
  }, [timerActive]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimerActive(false);
    }
  }, [timeLeft]);

  const restartTimer = () => {
    onClick();
    setTimeLeft(timerCount);
    setTimerActive(true);
  };

  return (
    <div>
      {timeLeft > 0 ? (
        <BodyRegular2 color={Colors.TEXT_SECONDARY}>{`${label} 00:${String(
          Math.floor(timeLeft / 10)
        )}${timeLeft % 10}`}</BodyRegular2>
      ) : (
        <div onClick={restartTimer} style={{ cursor: "pointer" }}>
          <BodyBold2 color={Colors.ORANGE_60}>Resend</BodyBold2>
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
