import React from "react";
import { Colors } from "../../../constants";

import "./index.css";

const Button = ({
  buttonSize,
  disabled,
  type,
  leftIcon,
  disabledLeftIcon,
  rightIcon,
  disabledRightIcon,
  title = "Continue",
  loading = false,
  width,
  onPress = () => {},
  customButtonStyle = {},
  customTitleStyle = {},
}) => {
  const WidthStyle = {
    dynamic: {
      textStyle: {},
      viewStyle: {
        alignSelf: "flex-start",
      },
    },
    full: {
      textStyle: {},
      viewStyle: {},
    },
  };

  const TypeStyle = {
    primary: {
      textStyle: {
        color: Colors.GRAY_0,
      },
      viewStyle: {
        backgroundColor: Colors.ORANGE_60,
        borderWidth: 0,
      },
    },
    secondary: {
      textStyle: {
        color: Colors.ORANGE_60,
      },
      viewStyle: {
        backgroundColor: Colors.GRAY_0,
        border: "1.5px solid",
        borderColor: Colors.ORANGE_60,
      },
    },
  };

  const SizeStyle = {
    large: {
      textStyle: {
        fontSize: "18px",
        lineHeight: "24px",
      },
      viewStyle: {
        padding: "16px",
        borderRadius: "16px",
      },
    },
    small: {
      textStyle: {
        fontSize: "16px",
        lineHeight: "20px",
      },
      viewStyle: {
        padding: "10px 16px",
        borderRadius: "12px",
      },
    },
  };

  const DisabledStyle = {
    true: {
      textStyle: {
        color: Colors.TEXT_DISABLED,
      },
      viewStyle: {
        backgroundColor:
          type === "primary"
            ? Colors.GRAY_10
            : type === "secondary"
            ? Colors.GRAY_0
            : "transparent",
        ...(type === "secondary" && { borderColor: Colors.TEXT_DISABLED }),
      },
    },
    false: {
      textStyle: {},
      viewStyle: {
        cursor: "pointer",
      },
    },
  };

  const getViewStyle = ({ buttonSize, disabled, type, width }) => {
    let viewStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
      justifyContent: "center",
    };

    switch (width) {
      case "dynamic":
        viewStyle = { ...viewStyle, ...WidthStyle.dynamic.viewStyle };
        break;
      case "full":
        viewStyle = { ...viewStyle, ...WidthStyle.full.viewStyle };
        break;
      default:
        break;
    }

    switch (buttonSize) {
      case "large":
        viewStyle = { ...viewStyle, ...SizeStyle.large.viewStyle };
        break;
      case "small":
        viewStyle = { ...viewStyle, ...SizeStyle.small.viewStyle };
        break;
      default:
        break;
    }

    switch (type) {
      case "primary":
        viewStyle = { ...viewStyle, ...TypeStyle.primary.viewStyle };
        break;
      case "secondary":
        viewStyle = { ...viewStyle, ...TypeStyle.secondary.viewStyle };
        break;
      case "tertiary":
        viewStyle = { ...viewStyle, ...TypeStyle.tertiary.viewStyle };
        break;
      default:
        break;
    }

    switch (disabled) {
      case true:
        viewStyle = { ...viewStyle, ...DisabledStyle.true.viewStyle };
        break;
      case false:
        viewStyle = { ...viewStyle, ...DisabledStyle.false.viewStyle };
        break;
      default:
        break;
    }

    return viewStyle;
  };

  const getTextStyle = ({ buttonSize, disabled, type }) => {
    let textStyle = {
      textAlign: "center",
      fontFamily: "Manrope",
      fontWeight: "700",
    };

    switch (buttonSize) {
      case "large":
        textStyle = { ...textStyle, ...SizeStyle.large.textStyle };
        break;
      case "small":
        textStyle = { ...textStyle, ...SizeStyle.small.textStyle };
        break;
      default:
        break;
    }

    switch (type) {
      case "primary":
        textStyle = { ...textStyle, ...TypeStyle.primary.textStyle };
        break;
      case "secondary":
        textStyle = { ...textStyle, ...TypeStyle.secondary.textStyle };
        break;
      case "tertiary":
        textStyle = { ...textStyle, ...TypeStyle.tertiary.textStyle };
        break;
      default:
        break;
    }

    switch (disabled) {
      case true:
        textStyle = { ...textStyle, ...DisabledStyle.true.textStyle };
        break;
      case false:
        textStyle = { ...textStyle, ...DisabledStyle.false.textStyle };
        break;
      default:
        break;
    }

    return textStyle;
  };

  const getIconStyle = (buttonSize) => {
    switch (buttonSize) {
      case "large":
        return { height: "24px", width: "24px", fontSize: "18px" };
      case "small":
        return { height: "20px", width: "20px", fontSize: "14px" };
      default:
        break;
    }
  };

  return (
    <button
      onClick={onPress}
      disabled={disabled || loading}
      style={{
        ...getViewStyle({ buttonSize, type, disabled, width }),
        ...customButtonStyle,
      }}
    >
      {!disabled && loading ? (
        <div
          className={`button-loader ${
            type === "primary" ? "primary-loader" : "secondary-loader"
          }`}
        />
      ) : (
        <>
          {leftIcon &&
            disabledLeftIcon &&
            (disabled
              ? React.cloneElement(disabledLeftIcon, getIconStyle(buttonSize))
              : React.cloneElement(leftIcon, getIconStyle(buttonSize)))}
          <span
            style={{
              ...getTextStyle({ buttonSize, type, disabled }),
              ...customTitleStyle,
            }}
          >
            {title}
          </span>
          {rightIcon &&
            disabledRightIcon &&
            (disabled
              ? React.cloneElement(disabledRightIcon, getIconStyle(buttonSize))
              : React.cloneElement(rightIcon, getIconStyle(buttonSize)))}
        </>
      )}
    </button>
  );
};

export default Button;
