import React, { useEffect, useRef, useState } from "react";

import {
  BodyBold2,
  BodyRegular2,
  Header1,
} from "../../components/styled/Typography";
import { Colors, Events, StorageKeys } from "../../constants";
import {
  Container,
  Button,
  Input as InputField,
  TnCModal,
} from "../../components/atoms";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faPhone,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
import { generateSession, requestProfileOtp } from "../../apis";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./index.css";
import { useAlert } from "../../context/alert/AlertContext";
import { trackEvent } from "../../analytics";
import { useSession } from "../../context/session/SessionContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [tnc, setTnc] = useState(false);
  const tncRef = useRef(null);

  const { showAlert } = useAlert();
  const { startTimer, resetTimer } = useSession();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const onClickViewTnC = () => {
    tncRef?.current?.show();
  };

  const onClickUseAadhar = () => {
    navigate(-1);
  };

  const backHandler = () => {
    navigate(-1);
  };

  const handleError = (response) => {
    if (response?.error?.code === "ABDM-1115") {
      setError("Number not linked with ABHA account");
      return;
    }
    showAlert(response?.error?.message ?? "Something went wrong!");
    return;
  };

  const onPressViewYourAbha = async () => {
    trackEvent(Events.ABHA_LOGIN_PHONE_SUBMIT, {});
    const payload = {
      loginType: "mobile",
      loginValue: phoneNumber,
    };
    setLoading(true);
    const session_response = await generateSession();
    if (session_response?.code === 1) {
      const response = await requestProfileOtp(payload);
      if (response?.code === 1) {
        startTimer();
        navigate(`/otp?mode=number`, {
          state: { message: response?.data?.message, phoneNumber },
        });
      } else {
        handleError(response);
      }
    } else {
      handleError(session_response);
    }
    setLoading(false);
  };

  const logMoengageEvent = () => {
    trackEvent(Events.ABHA_LOGIN_LANDING_PAGE, {});
  };

  useEffect(() => {
    logMoengageEvent();
  }, []);

  useEffect(() => {
    resetTimer();
  }, [location]);

  return (
    <Container
      showBack={
        (searchParams.get(StorageKeys.SOURCE) ||
          JSON.parse(localStorage.getItem(StorageKeys.QUERY_PARAMS))?.[
            StorageKeys.SOURCE
          ]) === "MyTatva"
      }
      onPressBack={backHandler}
    >
      <div className="login-page-container">
        <div className="top-app-bar">
          <img
            src={require("../../assets/images/tatvacare_logo.png")}
            className="tatvacare-logo"
          />
        </div>
        <div className="form-container">
          <div>
            <Header1 color={Colors.BLACK}>View ABHA account</Header1>
            <BodyRegular2 color={Colors.TEXT_SECONDARY}>
              Issued by the National Health Authority through the Ayushman
              Bharat Digital Mission (ABDM)
            </BodyRegular2>
          </div>
          <InputField
            label={"Phone Number"}
            prefix={"+91"}
            autoFocus={true}
            error={error}
            leftIcon={<FontAwesomeIcon icon={faPhone} className="input-icon" />}
            inputProps={{
              placeholder: "Enter Phone Number",
              placeholdercolor: Colors.TEXT_TERTIARY,
              type: "tel",
              maxLength: 10,
              minLength: 10,
              value: phoneNumber,
              onChange: (e) => {
                if (/^-?\d*$/.test(e.target.value)) {
                  setPhoneNumber(e.target.value);
                }
              },
            }}
          />
          <div className="tnc-container">
            <div
              onClick={() => setTnc(!tnc)}
              className="tnc-icon-container"
              style={{ cursor: "pointer" }}
            >
              {tnc ? (
                <FontAwesomeIcon icon={faSquareCheck} className="tnc-icon" />
              ) : (
                <FontAwesomeIcon icon={faSquare} className="tnc-icon" />
              )}
            </div>
            <BodyRegular2 color={Colors.TEXT_PRIMARY}>
              I agree with the terms and conditions
            </BodyRegular2>
            <div style={{ cursor: "pointer" }}>
              <BodyBold2 color={Colors.ORANGE_60} onClick={onClickViewTnC}>
                View
              </BodyBold2>
            </div>
          </div>
          <Button
            buttonSize={"large"}
            disabled={phoneNumber.length !== 10 || !tnc}
            loading={loading}
            type={"primary"}
            width={"full"}
            title={"View your ABHA"}
            onPress={onPressViewYourAbha}
            rightIcon={
              <FontAwesomeIcon
                icon={faArrowRight}
                className="enabled-arrow-icon"
              />
            }
            disabledRightIcon={
              <FontAwesomeIcon
                icon={faArrowRight}
                className="disabled-arrow-icon"
              />
            }
          />
          <div>
            <BodyRegular2 color={Colors.TEXT_PRIMARY}>
              Don't have ABHA linked mobile number?
            </BodyRegular2>
            <div onClick={onClickUseAadhar} style={{ cursor: "pointer" }}>
              <BodyBold2 color={Colors.ORANGE_60}>Use Aadhar number</BodyBold2>
            </div>
          </div>
        </div>
      </div>
      <TnCModal ref={tncRef} />
    </Container>
  );
};

export default LoginPage;
