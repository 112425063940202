import React, { useEffect, useRef, useState } from "react";

import { BodyRegular2, CaptionRegular1 } from "../../styled/Typography";
import { Colors } from "../../../constants";

import "./index.css";

const InputField = ({
  label = "",
  leftIcon = <></>,
  prefix = "",
  suffix = "",
  error = null,
  inputProps = {},
  autoFocus = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputRef?.current) {
      inputRef?.current?.blur();
    }
  };

  useEffect(() => {
    if (autoFocus && inputRef?.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 100);
    }
  }, [autoFocus]);

  return (
    <div className="input-container">
      <BodyRegular2
        color={Colors.TEXT_TERTIARY}
        style={{ textAlign: "left", margin: "0px 8px" }}
      >
        {label}
      </BodyRegular2>
      <div
        className={`text-input-container ${
          isFocused ||
          (document.getElementById("text-input-id") &&
            document.getElementById("text-input-id").value)
            ? "focused-input"
            : "unfocused-input"
        } ${error && "input-field-error"}`}
      >
        {leftIcon}
        {(isFocused ||
          (document.getElementById("text-input-id") &&
            document.getElementById("text-input-id").value)) && (
          <div className="prefix">{prefix}</div>
        )}
        <input
          id="text-input-id"
          ref={inputRef}
          onFocus={() => {
            handleFocus();
            if (inputProps?.onFocus) {
              inputProps.onFocus();
            }
          }}
          onBlur={() => {
            handleBlur();
            if (inputProps?.onBlur) {
              inputProps.onBlur();
            }
          }}
          onKeyDown={handleKeyDown}
          className="text-input"
          {...inputProps}
        />
        <div className="suffix">{suffix}</div>
      </div>
      {error && (
        <CaptionRegular1
          color={Colors.RED_60}
          style={{ textAlign: "left", marginLeft: "8px" }}
        >
          {error}
        </CaptionRegular1>
      )}
    </div>
  );
};

export default InputField;
