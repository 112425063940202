import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Page Imports
import SignupPage from "./pages/signup";
import LoginPage from "./pages/login";
import ProfilePage from "./pages/profile";
import OTPPage from "./pages/otp";
import UpdateInfoPage from "./pages/update";
import AbhaListPage from "./pages/abhalist";
import NewAbhaAddressPage from "./pages/new-abha-address";

import { AlertProvider } from "./context/alert/AlertContext";
import { SessionProvider } from "./context/session/SessionContext";

import "./App.css";

const App = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "Are you sure you want to leave? Your changes might not be saved.";
      event.preventDefault();
      event.returnValue = message;
      return message;
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="App">
      <div className="App-View-Container">
        <AlertProvider>
          <SessionProvider>
            <Router>
              <Routes>
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/otp" element={<OTPPage />} />
                <Route path="/update" element={<UpdateInfoPage />} />
                <Route path="/abha-list" element={<AbhaListPage />} />
                <Route
                  path="/new-abha-address"
                  element={<NewAbhaAddressPage />}
                />
                <Route path="/*" element={<Navigate to={"/signup"} />} />
              </Routes>
            </Router>
          </SessionProvider>
        </AlertProvider>
      </div>
    </div>
  );
};

export default App;
