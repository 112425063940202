export const Methods = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
  PATCH: "patch",
};

export const Routes = {
  GENERATE_SESSION: "/abha/session",
  REQUEST_ENROLMENT_OTP: "/abha/requestOtp/enrollment",
  VERIFY_ENROLMENT_BY_AADHAR_OTP: "/abha/verifyOtp/byaadhaar",
  VERIFY_ENROLMENT_OTP: "/abha/verifyOtp/byAbdm",
  REQUEST_PROFILE_OTP: "/abha/requestOtp/profile",
  VERIFY_PROFILE_OTP: "/abha/verifyOtp/profile",
  VERIFY_USER: "/abha/verify/user",
  FETCH_ABHA_PROFILE: "/abha/profile",
  FETCH_DETAILS_BY_MOBILE: "/abha/details",
  FETCH_ABHA_ADDRESS_SUGGESTIONS: "/abha/address/suggestion",
  CREATE_ABHA_ADDRESS: "/abha/address/create",
  DOWNLOAD_ABHA_CARD: "/abha/download",
};

export const avoidTxnIdEndpoints = [
  Routes.FETCH_ABHA_PROFILE,
  Routes.REQUEST_PROFILE_OTP,
  Routes.DOWNLOAD_ABHA_CARD,
];

export const storeXTokenEndpoints = [
  Routes.VERIFY_ENROLMENT_BY_AADHAR_OTP,
  Routes.VERIFY_USER,
];
