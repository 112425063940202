import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import ReactDOM from "react-dom";

import "./index.css";
import { BodyRegular2, Header1 } from "../../styled/Typography";
import { Colors } from "../../../constants";
import Lottie from "lottie-react";

const SuccessModal = forwardRef(({ autoHideDuration = 4000 }, ref) => {
  const [visible, setVisible] = useState(false);
  const [header, setHeader] = useState("");
  const [subtitle, setSubtitle] = useState("");

  const show = ({ header = "", subtitle = "" }) => {
    setHeader(header);
    setSubtitle(subtitle);
    setVisible(true);
  };

  const hide = () => {
    setHeader("");
    setSubtitle("");
    setVisible(false);
  };

  // Automatically hide after a specified duration
  useEffect(() => {
    let timer;
    if (visible) {
      timer = setTimeout(() => {
        hide();
      }, autoHideDuration);
    }
    return () => clearTimeout(timer);
  }, [visible, autoHideDuration]);

  // Expose show and hide methods to parent components via ref
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  // Render the popup
  return visible
    ? ReactDOM.createPortal(
        <div className="success-modal-popup-overlay">
          <div className="success-modal-popup-content">
            <div className="success-modal-animation-container">
              <Lottie
                animationData={require("../../../assets/animations/success.json")}
                style={{ height: "100px", width: "100px" }}
                autoPlay
                loop
              />
            </div>
            <Header1
              color={Colors.TEXT_PRIMARY}
              style={{ textAlign: "center" }}
            >
              {header}
            </Header1>
            <BodyRegular2
              color={Colors.TEXT_PRIMARY}
              style={{ textAlign: "center" }}
            >
              {subtitle}
            </BodyRegular2>
          </div>
        </div>,
        document.body
      )
    : null;
});

export default SuccessModal;
