import { APIEndpoints, Methods } from "../constants";
import request from "./api";

export const requestProfileOtp = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.REQUEST_PROFILE_OTP,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      console.log("[ERROR REQUESTING PROFILE OTP]: ", response);
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR REQUESTING PROFILE OTP]: ", error);
    return {
      code: 0,
      message: "[ERROR REQUESTING PROFILE OTP]",
      error: JSON.parse(error.message),
    };
  }
};

export const verifyProfileOtp = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.VERIFY_PROFILE_OTP,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR VERIFYING PROFILE OTP]: ", error);
    return {
      code: 0,
      message: "[ERROR VERIFYING PROFILE OTP]",
      error: JSON.parse(error.message),
    };
  }
};

export const verifyUser = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.VERIFY_USER,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR VERIFYING USER]: ", error);
    return {
      code: 0,
      message: "[ERROR VERIFYING USER]",
      error: JSON.parse(error.message),
    };
  }
};

export const fetchABHAProfiles = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.FETCH_ABHA_PROFILE,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR FETCHING ABHA PROFILES]: ", error);
    return {
      code: 0,
      message: "[ERROR FETCHING ABHA PROFILES]",
      error: JSON.parse(error.message),
    };
  }
};

export const getDetailsByMobile = async (queryParams) => {
  try {
    const response = await request({
      url: APIEndpoints.FETCH_DETAILS_BY_MOBILE,
      method: Methods.GET,
      queryParams,
    });

    if (response?.code === 0) {
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR FETCHING DETAILS BY MOBILE]: ", error);
    return {
      code: 0,
      message: "[ERROR FETCHING DETAILS BY MOBILE]",
      error: JSON.parse(error.message),
    };
  }
};

export const downloadAbhaCard = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.DOWNLOAD_ABHA_CARD,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      console.log("[ERROR DOWNLOADING ABHA CARD]: ", response);
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR DOWNLOADING ABHA CARD]: ", error);
    return {
      code: 0,
      message: "[ERROR DOWNLOADING ABHA CARD]",
      error: JSON.parse(error.message),
    };
  }
};
