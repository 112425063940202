import styled from "styled-components";

export const Header1 = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const Header2 = styled.div`
  font-size: 22px;
  line-height: 26px;
  font-weight: 700;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const Header3 = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const Header4 = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const BodyRegular1 = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const BodyBold1 = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const BodyBold2 = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const BodyRegular2 = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const BodyBold3 = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const BodyRegular3 = styled.div`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const CaptionBold1 = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;

export const CaptionRegular1 = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  color: ${(props) => props.color ?? "#000000"};
`;
