import { APIEndpoints, Methods, StorageKeys } from "../constants";
import request, { encryptData } from "./api";

export const generateSession = async () => {
  try {
    const response = await request({
      url: APIEndpoints.GENERATE_SESSION,
      method: Methods.GET,
    });

    if (response?.code === 0) {
      throw new Error(JSON.stringify(response?.data?.error ?? {code: 'MYT-0000', message: 'Something went wrong!'}));
    }

    const accessToken = response?.data?.accessToken;
    const refreshToken = response?.data?.refreshToken;

    localStorage.setItem(StorageKeys.ACCESS_TOKEN, encryptData(accessToken));
    localStorage.setItem(StorageKeys.REFRESH_TOKEN, encryptData(refreshToken));

    return response;
  } catch (error) {
    console.log("[ERROR GENERATING SESSION]: ", error);
    return { code: 0, message: "[ERROR GENERATING SESSION]", error: JSON.parse(error.message) };
  }
};
