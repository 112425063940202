const Events = {
  ABHA_PAGE_LANDING: "ABHA_PageLanding",
  ABHA_CREATE_ABHA_CLICKED: "ABHA_Create_abha_clicked",
  ABHA_AADHAR_OTP_SENT: "ABHA_Aadhar_OTP_sent",
  ABHA_OTP_MOBILE_SUBMIT_CLICKED: "ABHA_OTP_Mobile_Submit_clicked",
  ABHA_CREATION_ERROR: "ABHA_Creation_Error",
  ABHA_ALREADY_EXISTS: "ABHA_Already_Exists",
  ABHA_CREATED_SUCCESSFULLY: "ABHA_Created_successfully",
  ABHA_USER_DETAIL_LANDING: "ABHA_User_Detail_Landing",
  ABHA_LOGIN_CLICKED: "ABHA_login_clicked",
  ABHA_LOGIN_LANDING_PAGE: "ABHA_Login_landing_page",
  ABHA_LOGIN_PHONE_SUBMIT: "ABHA_login_Phone_submit",
  ABHA_LOGIN_OTP_VERIFIED: "ABHA_login_OTP_Verified",
  ABHA_LOGIN_ERROR: "ABHA_login_error",
};

export { Events };
