import React, { useEffect, useRef, useState } from "react";

import { Button, Container, Input, SuccessModal } from "../../components/atoms";
import {
  BodyBold2,
  BodyRegular2,
  BodyRegular3,
  CaptionRegular1,
  Header1,
} from "../../components/styled/Typography";
import { Colors, StorageKeys } from "../../constants";
import { faCircleCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lottie from "lottie-react";
import {
  createAbhaAddress,
  fetchAbhaAddressSuggestions,
} from "../../apis/address";
import { useLocation, useNavigate } from "react-router-dom";

import { useAlert } from "../../context/alert/AlertContext";

import "./index.css";

const NewAbhaAddressPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { showAlert } = useAlert();

  const [abha, setAbha] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);

  const [loading, setLoading] = useState(false);

  const modalRef = useRef(null);

  const hasUppercase = (str) => {
    return str !== str.toLowerCase();
  };

  const validateAddress = (string = "") => {
    if (hasUppercase(string)) {
      //String must not contain uppercase
      return false;
    }

    const strArr = string.split("");
    if (strArr.length > 18 || strArr.length < 8) {
      //String must have 8-18 characters
      return false;
    }

    if (strArr.filter((i) => i === ".").length > 1) {
      //String can only contain at most 1 dot ( . )
      return false;
    }

    if (strArr.filter((i) => i === "_").length > 1) {
      //String can only contain at most 1 underscore ( _ )
      return false;
    }

    if (/[ `!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?~]/.test(string)) {
      //String cannot contain special characters other than ( . ) or ( _ )
      return false;
    }

    return true;
  };

  const isAbhaAddressValid = validateAddress(abha);

  const renderSuggestions = (item, index) => {
    const isSelected = abha === item;
    return (
      <div
        key={index.toString()}
        onClick={() => {
          if (isSelected) {
            setAbha("");
          } else {
            setAbha(item);
          }
        }}
        className={`abha-id-suggestion-item ${
          isSelected && "abha-id-selected-suggestion"
        }`}
      >
        <BodyBold2
          color={isSelected ? Colors.TEXT_PRIMARY : Colors.TEXT_TERTIARY}
        >
          {item}
        </BodyBold2>
        {isSelected && <FontAwesomeIcon icon={faXmark} className="x-icon" />}
      </div>
    );
  };

  const onClickViewMore = () => {
    setIsViewMore(!isViewMore);
  };

  const getDisplayArray = () => {
    return (
      (isViewMore ? suggestions ?? [] : suggestions.slice(0, 10) ?? []) ?? []
    );
  };

  const handleError = (response) => {
    showAlert(response?.error?.message ?? "Something went wrong!");
  };

  const onPressAddAddress = async () => {
    const payload = {
      abhaAddress: abha,
      ABHANumber: location.state?.abhaNumber,
      isPreferred: true,
      xToken: localStorage?.getItem(StorageKeys.X_TOKEN),
    };
    setLoading(true);
    const create_abha_address_response = await createAbhaAddress(payload);
    if (create_abha_address_response?.code === 1) {
      modalRef?.current?.show({
        header: "ABHA address changed successfully",
        subtitle: `Your ABHA address has been changed to ${abha}@abdm`,
      });
      setTimeout(() => {
        // navigate(`/${isOldUser}/profile`, {
        navigate(`/profile`, {
          state: {
            phoneNumber: location.state?.phoneNumber,
          },
        });
      }, 4000);
    } else {
      handleError(create_abha_address_response);
    }
    setLoading(false);
  };

  const fetchSuggestions = async () => {
    const payload = {};
    setIsFetching(true);
    const fetch_abha_address_suggestions_response =
      await fetchAbhaAddressSuggestions(payload);
    if (fetch_abha_address_suggestions_response?.code === 1) {
      setSuggestions(
        fetch_abha_address_suggestions_response?.data?.abhaAddressList
      );
    } else {
      setSuggestions([]);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchSuggestions();
  }, []);

  return (
    <Container
      showBack
      onPressBack={() => {
        navigate(-1);
      }}
    >
      <div className="new-abha-address-screen-container">
        <div>
          <Header1 color={Colors.TEXT_PRIMARY}>Add new ABHA address</Header1>
          <BodyRegular2 color={Colors.TEXT_SECONDARY}>
            Create a new ABHA address to link with your ABHA account
          </BodyRegular2>
        </div>
        <div className="abha-form-container">
          <Input
            label={"ABHA Address"}
            suffix="@abdm"
            autoFocus={true}
            inputProps={{
              placeholder: "Enter address here",
              placeholdercolor: Colors.TEXT_TERTIARY,
              type: "text",
              value: abha,
              onChange: (e) => setAbha(e.target.value),
            }}
          />
          <div className="rule-container">
            <div className="abha-validation-container">
              {isAbhaAddressValid ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="checkmark-icon"
                />
              ) : (
                <Lottie
                  animationData={require("../../assets/animations/abha_validator.json")}
                  style={{ height: "20px", width: "20px" }}
                  autoPlay
                  loop
                />
              )}
              <BodyRegular2 color={Colors.TEXT_PRIMARY}>
                Minimum 8 characters
              </BodyRegular2>
            </div>
            <BodyRegular3 color={Colors.TEXT_PRIMARY}>
              Note: Special characters allowed: dot(.) or underscore(_)
            </BodyRegular3>
          </div>
          {!isFetching && (suggestions ?? []).length > 0 && (
            <div>
              <CaptionRegular1
                color={Colors.TEXT_SECONDARY}
                style={{ margin: "0px 6px" }}
              >
                Here are some suggestions you can use
              </CaptionRegular1>
              <div className="suggestion-container">
                {getDisplayArray().map(renderSuggestions)}
              </div>
              <div onClick={onClickViewMore} style={{ cursor: "pointer" }}>
                <BodyBold2 color={Colors.ORANGE_60}>
                  {isViewMore ? "View Less" : "View More"}
                </BodyBold2>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="new-abha-address-btn-container">
        <Button
          buttonSize={"large"}
          disabled={!isAbhaAddressValid}
          loading={loading}
          type={"primary"}
          width={"full"}
          customButtonStyle={{
            width: "calc(100dvw - 32px)",
            maxWidth: "448px",
          }}
          title="Add Address"
          onPress={onPressAddAddress}
        />
      </div>
      <SuccessModal ref={modalRef} />
    </Container>
  );
};

export default NewAbhaAddressPage;
