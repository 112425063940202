import React, { createContext, useState, useContext, useEffect } from "react";

import { Colors, StorageKeys } from "../../constants";
import { BodyRegular1, Header2 } from "../../components/styled/Typography";
import { Button } from "../../components/atoms";

import "./index.css";
import { logout } from "../../analytics";

const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const initialTime = 20 * 60; // 20 minutes in seconds
  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem(StorageKeys.TIME_LEFT);
    return savedTime ? parseInt(savedTime, 10) : initialTime;
  });
  const [isActive, setIsActive] = useState(() => {
    const savedTime = localStorage.getItem(StorageKeys.TIME_LEFT);
    return !!savedTime;
  });

  useEffect(() => {
    let interval = null;

    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem(StorageKeys.TIME_LEFT, newTime);
          return newTime;
        });
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(interval);
      setIsActive(false);
      localStorage.removeItem(StorageKeys.TIME_LEFT); // Clear the time when it reaches 0
      localStorage.setItem(StorageKeys.SESSION_EXPIRED_ALERT, "true");
    }

    return () => clearInterval(interval);
  }, [isActive, timeLeft]);

  const resetTimer = () => {
    setTimeLeft(initialTime);
    localStorage.removeItem(StorageKeys.SESSION_EXPIRED_ALERT);
    localStorage.setItem(StorageKeys.TIME_LEFT, initialTime); // Reset localStorage as well
    setIsActive(false);
  };

  const startTimer = () => {
    resetTimer();
    setIsActive(true);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}m ${String(secs).padStart(
      2,
      "0"
    )}s`;
  };

  const onPressLogin = () => {
    resetTimer();
    localStorage.removeItem(StorageKeys.LOGIN_FLOW);
    localStorage.removeItem(StorageKeys.TRANSACTION_ID);
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
    localStorage.removeItem(StorageKeys.X_TOKEN);
    localStorage.removeItem(StorageKeys.LOGGED_IN_USER);
    logout();
    window.location.replace("/signup");
  };

  return (
    <SessionContext.Provider value={{ startTimer, resetTimer, isActive }}>
      {isActive && (
        <span className="session-timer-text">
          Your session will expire in {formatTime(timeLeft)}
        </span>
      )}
      {children}
      {localStorage.getItem(StorageKeys.SESSION_EXPIRED_ALERT) === "true" && (
        <div className="session-expired-alert-background">
          <div className="session-expired-alert-container">
            <Header2 color={Colors.TEXT_PRIMARY}>Session Expired</Header2>
            <BodyRegular1 color={Colors.TEXT_SECONDARY}>
              Your session is expired. Please login to continue working again
            </BodyRegular1>
            <Button
              buttonSize={"small"}
              disabled={false}
              type={"primary"}
              width={"dynamic"}
              customButtonStyle={{ alignSelf: "flex-end" }}
              title="Login"
              onPress={onPressLogin}
            />
          </div>
        </div>
      )}
    </SessionContext.Provider>
  );
};
