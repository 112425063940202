import { APIEndpoints, Methods } from "../constants";
import request from "./api";

export const requestEnrolmentOtp = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.REQUEST_ENROLMENT_OTP,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      return response;
    }

    return response;
  } catch (error) {
    console.log("[ERROR REQUESTING ENROLMENT OTP]: ", error);
    return {
      code: 0,
      message: "[ERROR REQUESTING ENROLMENT OTP]",
      error: JSON.parse(error?.message),
    };
  }
};

export const verifyEnrolmentByAadharOtp = async ({ payload, queryParams }) => {
  try {
    const response = await request({
      url: APIEndpoints.VERIFY_ENROLMENT_BY_AADHAR_OTP,
      method: Methods.POST,
      data: payload,
      queryParams,
    });

    if (response?.code === 0) {
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR VERIFYING ENROLMENT BY AADHAR OTP]: ", error);
    return {
      code: 0,
      message: "[ERROR VERIFYING ENROLMENT BY AADHAR OTP]",
      error: JSON.parse(error.message),
    };
  }
};

export const verifyEnrolmentOtp = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.VERIFY_ENROLMENT_OTP,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR VERIFYING ENROLMENT OTP]: ", error);
    return {
      code: 0,
      message: "[ERROR VERIFYING ENROLMENT OTP]",
      error: JSON.parse(error.message),
    };
  }
};
