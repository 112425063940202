import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { BodyRegular2 } from "../../styled/Typography";
import { Colors } from "../../../constants";

const AadharCardInput = ({ aadhar = "", setAadhar = () => {} }) => {
  const inputRefs = [useRef(null), useRef(null), useRef(null)];
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (index) => (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 4);
    e.target.value = value;

    // Update Aadhar based on the current input
    const combinedValue = inputRefs.map((ref) => ref.current.value).join("");
    setAadhar(combinedValue);

    // If the current input is filled and it’s the last input, blur all inputs
    if (value.length === 4) {
      if (index === inputRefs.length - 1) {
        // Blur all inputs if the last one is filled
        inputRefs.forEach((ref) => ref.current.blur());
      } else if (inputRefs[index + 1]) {
        // Move focus to the next input
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index) => (e) => {
    // If Backspace is pressed and the current input is empty
    if (e.key === "Backspace" && inputRefs[index].current.value === "") {
      if (inputRefs[index - 1]) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleFocus = () => setIsFocused(true);

  useEffect(() => {
    setIsFocused(aadhar.length > 0);
  }, [aadhar]);

  return (
    <div className="aadhar-container">
      <div className="aadhar-header">
        <img
          src={require("../../../assets/images/aadhar_details.png")}
          alt="Aadhar Details"
          className="aadhar-details"
        />
      </div>
      <div className="aadhar-footer">
        <BodyRegular2 color={Colors.TEXT_TERTIARY}>
          Enter Your Aadhar Number
        </BodyRegular2>
        <div className="triple-input-container">
          {inputRefs.map((ref, index) => (
            <input
              key={index}
              ref={ref}
              type="tel"
              maxLength={4}
              onFocus={handleFocus}
              onInput={handleInputChange(index)}
              onKeyDown={handleKeyDown(index)}
              className={`input ${isFocused && "focused"}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AadharCardInput;
