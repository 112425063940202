const sendMessageToParent = (eventName, data = {}) => {
  try {
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ action: eventName, data })
    );
  } catch (error) {
    console.log("sendMessageToParent ERROR", error);
  }
};

export default sendMessageToParent;
