import React, { useState, forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";

import { BodyRegular3, Header3 } from "../../styled/Typography";
import { Colors } from "../../../constants";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.css";

const TnCModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  // Expose show and hide methods to parent components via ref
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  // Render the popup
  return visible
    ? ReactDOM.createPortal(
        <div className="tnc-modal-popup-overlay">
          <div className="tnc-modal-popup-content">
            <div className="tnc-header-container">
              <Header3 color={Colors.TEXT_PRIMARY}>
                Terms and Conditions
              </Header3>
              <div className="tnc-modal-close-icon" onClick={hide}>
                <FontAwesomeIcon icon={faCircleXmark} />
              </div>
            </div>
            <BodyRegular3 color={Colors.TEXT_SECONDARY}>
              I, hereby declare that I am voluntarily sharing my Aadhaar Number
              and demographic information issued by UIDAI, with National Health
              Authority (NHA) for the sole purpose of creation of ABHA number .
              I understand that my ABHA number can be used and shared for
              purposes as may be notified by ABDM from time to time including
              provision of healthcare services. Further, I am aware that my
              personal identifiable information (Name, Address, Age, Date of
              Birth, Gender and Photograph) may be made available to the
              entities working in the National Digital Health Ecosystem (NDHE){" "}
              which inter alia includes stakeholders and entities such as
              healthcare professionals (e.g. doctors), facilities (e.g.
              hospitals, laboratories) and data fiduciaries (e.g. health
              programmes), which are registered with or linked to the Ayushman
              Bharat Digital Mission (ABDM) , and various processes there under.
            </BodyRegular3>
          </div>
        </div>,
        document.body
      )
    : null;
});

export default TnCModal;
