import { APIEndpoints, Methods } from "../constants";
import request from "./api";

export const fetchAbhaAddressSuggestions = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.FETCH_ABHA_ADDRESS_SUGGESTIONS,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR FETCHING ABHA ADDRESS SUGGESTIONS]: ", error);
    return {
      code: 0,
      message: "[ERROR FETCHING ABHA ADDRESS SUGGESTIONS]",
      error: JSON.parse(error?.message),
    };
  }
};

export const createAbhaAddress = async (payload) => {
  try {
    const response = await request({
      url: APIEndpoints.CREATE_ABHA_ADDRESS,
      method: Methods.POST,
      data: payload,
    });

    if (response?.code === 0) {
      throw new Error(
        JSON.stringify(
          response?.data?.error ?? {
            code: "MYT-0000",
            message: "Something went wrong!",
          }
        )
      );
    }

    return response;
  } catch (error) {
    console.log("[ERROR CREATING ABHA ADDRESS]: ", error);
    return {
      code: 0,
      message: "[ERROR CREATING ABHA ADDRESS]",
      error: JSON.parse(error?.message),
    };
  }
};
