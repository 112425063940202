import moengage from "@moengage/web-sdk";
import { StorageKeys } from "../constants";
import moment from "moment";

const moEngageUtils = {
  initialize: () => {
    moengage.initialize({
      app_id: process.env.REACT_APP_MOENGAGE_APP_ID,
      cluster: process.env.REACT_APP_MO_ENGAGE_DATA_CENTER,
      debug_logs: process.env.REACT_APP_MO_ENGAGE_ENABLE_DEBUG_LOGS,
    });
  },

  trackEvent: (eventName, eventData = {}) => {
    moengage?.track_event(eventName, {
      ...eventData,
      [StorageKeys.UTM_MEDIUM]: JSON.parse(
        localStorage.getItem(StorageKeys.QUERY_PARAMS)
      )?.[StorageKeys.UTM_MEDIUM],
      [StorageKeys.UTM_SOURCE]: JSON.parse(
        localStorage.getItem(StorageKeys.QUERY_PARAMS)
      )?.[StorageKeys.UTM_SOURCE],
    });
  },

  setUserAttributes: (attributes) => {
    try {
      const {
        ABHAProfile: {
          firstName,
          middleName,
          lastName,
          dob,
          gender,
          stateName,
          ABHANumber,
        },
      } = attributes;

      moengage?.add_unique_user_id(ABHANumber);
      moengage?.add_user_name(firstName + " " + middleName + " " + lastName);
      moengage?.add_gender(
        gender === "M" ? "Male" : gender === "F" ? "Female" : ""
      );
      moengage?.add_birthday(moment(dob, "DD-MM-YYYY").toDate());
      moengage?.add_user_attribute("state", stateName);
    } catch (error) {
      console.error("Error while setting user attributes:", error);
      // You can choose to handle the error here or rethrow it if needed
      throw error;
    }
  },

  logout: () => {
    moengage?.destroy_session();
  },
};

export default moEngageUtils;
