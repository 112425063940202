import React, { useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import OTPInput from "react-otp-input";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Button,
  Container,
  CountdownTimer,
  Input,
  SuccessModal,
} from "../../components/atoms";
import {
  BodyRegular2,
  CaptionBold1,
  CaptionRegular1,
  Header1,
} from "../../components/styled/Typography";
import { Colors, Events, StorageKeys } from "../../constants";
import {
  fetchABHAProfiles,
  requestEnrolmentOtp,
  requestProfileOtp,
  verifyEnrolmentByAadharOtp,
  verifyEnrolmentOtp,
  verifyProfileOtp,
  verifyUser,
} from "../../apis";
import { useAlert } from "../../context/alert/AlertContext";

import "./index.css";
import { trackEvent, setUserAttributes } from "../../analytics";
import { encryptData } from "../../apis/api";

const NUMBER_REGEX = /^(?:[0-9]*)$/;
const FAILED_REGEX = /failed/i;

const OTPPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [searchParams] = useSearchParams();
  const modalRef = useRef(null);

  const { showAlert } = useAlert();

  const mode = searchParams.get("mode"); //number, email, aadhar

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const [loading, setLoading] = useState(false);

  const onClickResendOTP = async () => {
    switch (mode) {
      case "number":
        if (localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup") {
          //use enrolment API
          const resend_enrolment_mobile_otp_payload = {
            loginType: "mobile",
            loginValue: state?.phoneNumber,
          };

          const resend_enrolment_mobile_response = await requestEnrolmentOtp(
            resend_enrolment_mobile_otp_payload
          );
          if (resend_enrolment_mobile_response?.code !== 1) {
            if (
              resend_enrolment_mobile_response?.data?.loginId ===
              "Invalid LoginId"
            ) {
              showAlert("Entered input is not valid.");
            } else {
              showAlert(
                resend_enrolment_mobile_response?.error?.message ??
                  "Something went wrong!"
              );
            }
          }
        } else {
          // use profile API
          const resend_profile_mobile_otp_payload = {
            loginType: "mobile",
            loginValue: state?.phoneNumber,
          };

          const resend_profile_mobile_response = await requestProfileOtp(
            resend_profile_mobile_otp_payload
          );
          if (resend_profile_mobile_response?.code !== 1) {
            showAlert(
              resend_profile_mobile_response?.error?.message ??
                "Something went wrong!"
            );
          }
        }
        break;
      case "email":
        const resend_email_otp_payload = {
          loginType: "email",
          loginValue: state?.email,
        };
        const resend_email_otp_response = await requestEnrolmentOtp(
          resend_email_otp_payload
        );
        if (resend_email_otp_response?.code !== 1) {
          if (resend_email_otp_response?.data?.loginId === "Invalid LoginId") {
            showAlert("Entered input is invalid.");
          } else {
            showAlert(
              resend_email_otp_response?.error?.message ??
                "Something went wrong!"
            );
          }
        }
        break;
      case "aadhar":
        const resend_aadhar_otp_payload = {
          loginType: "aadhaar",
          loginValue: state?.aadhar,
        };

        const resend_aadhar_otp_response = await requestEnrolmentOtp(
          resend_aadhar_otp_payload
        );
        if (resend_aadhar_otp_response?.code !== 1) {
          if (resend_aadhar_otp_response?.data?.loginId === "Invalid LoginId") {
            showAlert("Entered input is invalid.");
          } else {
            showAlert(
              resend_aadhar_otp_response?.error?.message ??
                "Something went wrong!"
            );
          }
        }
        break;
      default:
        break;
    }
  };

  const getHeaderText = () => {
    switch (mode) {
      case "number":
        return "Confirm OTP for mobile number linking with ABHA";
      case "email":
        return "Confirm OTP for email linking with ABHA";
      case "aadhar":
        return "Confirm OTP";
      default:
        return "";
    }
  };

  const renderContent = () => {
    switch (mode) {
      case "number":
      case "email":
        return (
          <div className="otp-input-container">
            <OTPInput
              value={otp}
              inputType="tel"
              onChange={(e) => {
                if (NUMBER_REGEX.test(e)) setOtp(e);
              }}
              numInputs={6}
              shouldAutoFocus
              inputStyle={`input-style ${otpError && "otp-error-input-style"} `}
              renderSeparator={<div style={{ width: "16.4px" }} />}
              renderInput={(props) => <input {...props} />}
            />
            {otpError && (
              <CaptionRegular1 color={Colors.RED_60}>
                {otpError}
              </CaptionRegular1>
            )}
            <div className="row-text">
              <BodyRegular2 color={Colors.TEXT_SECONDARY}>
                Didn’t receive OTP?
              </BodyRegular2>
              &nbsp;&nbsp;
              <CountdownTimer
                timerCount={30}
                label="Resent OTP in "
                onClick={onClickResendOTP}
              />
            </div>
          </div>
        );
      case "aadhar":
        return (
          <div className="aadhar-content-container">
            <img
              src={require("../../assets/images/aadhar_stepper.png")}
              className="aadhar-stepper-image"
            />
            <div className="aadhar-input-gap">
              <div
                className="otp-input-container"
                style={{ width: "calc(100dvw - 82px)", maxWidth: "398px" }}
              >
                <OTPInput
                  value={otp}
                  onChange={(e) => {
                    if (NUMBER_REGEX.test(e)) setOtp(e);
                  }}
                  numInputs={6}
                  inputStyle={`input-style-aadhar ${
                    otpError && "error-otp-input-style-aadhar"
                  }`}
                  inputType="tel"
                  shouldAutoFocus
                  renderSeparator={<div style={{ width: "8px" }} />}
                  renderInput={(props) => <input {...props} />}
                />
                {otpError && (
                  <CaptionRegular1 color={Colors.RED_60}>
                    {otpError}
                  </CaptionRegular1>
                )}
                <div className="row-text">
                  <BodyRegular2 color={Colors.TEXT_SECONDARY}>
                    Didn’t receive OTP?
                  </BodyRegular2>
                  &nbsp;&nbsp;
                  <CountdownTimer
                    timerCount={30}
                    label="Resent OTP in "
                    onClick={onClickResendOTP}
                  />
                </div>
              </div>
              <div className="aadhar-mobile-input-container">
                <CaptionBold1 color={Colors.TEXT_PRIMARY}>
                  Add mobile number you want to link with ABHA
                </CaptionBold1>
                <Input
                  label={"Phone Number"}
                  prefix={"+91"}
                  leftIcon={
                    <FontAwesomeIcon icon={faPhone} className="input-icon" />
                  }
                  inputProps={{
                    placeholder: "Enter Phone Number",
                    placeholdercolor: Colors.TEXT_TERTIARY,
                    type: "tel",
                    maxLength: 10,
                    minLength: 10,
                    value: phoneNumber,
                    onChange: (e) => {
                      if (/^-?\d*$/.test(e.target.value)) {
                        setPhoneNumber(e.target.value);
                      }
                    },
                  }}
                />
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const handleAadharResponse = (response) => {
    if (response?.error?.code === "ABDM-1204") {
      setOtpError("Check and re-enter the OTP.");
      return;
    } else if (response?.error?.code === "ABDM-1100") {
      setOtpError("3 wrong attempts. Try again after 30 mins");
      return;
    }

    showAlert(response?.error?.message ?? "Something went wrong!");
    return;
  };

  const handleEmailResponse = (response) => {
    if (response?.code === 1) {
      if (
        response?.data?.message ===
        "Please enter a valid OTP. Entered OTP is either expired or incorrect."
      ) {
        setOtpError("Invalid OTP. Try again.");
        return;
      }
    } else {
      if (response?.error?.code === "ABDM-1204") {
        setOtpError("Check and re-enter the OTP.");
        return;
      } else if (response?.error?.code === "ABDM-1100") {
        setOtpError("3 wrong attempts. Try again after 30 mins");
        return;
      }
    }

    showAlert(response?.error?.message ?? "Something went wrong!");
    return;
  };

  const handleMobileResponse = (response) => {
    if (response?.code === 1) {
      if (
        response?.data?.message ===
        "Please enter a valid OTP. Entered OTP is either expired or incorrect."
      ) {
        setOtpError("Invalid OTP. Try again.");
        return;
      }
    } else {
      if (response?.error?.code === "ABDM-1100") {
        setOtpError("3 wrong attempts. Try again after 30 mins");
        return;
      }
    }

    showAlert(response?.error?.message ?? "Something went wrong!");
    return;
  };

  const logMoengageLoginErrorEvent = (response) => {
    if (response?.code === 1) {
      if (FAILED_REGEX.test(response?.data?.authResult)) {
        trackEvent(Events.ABHA_LOGIN_ERROR, {
          error: { code: null, message: response?.data?.message },
        });
      }
    } else {
      trackEvent(Events.ABHA_LOGIN_ERROR, { error: response?.error });
    }
  };

  const onPressContinue = async () => {
    setOtpError("");
    switch (mode) {
      case "number":
        if (localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup") {
          //use enrolment API
          const enrolment_by_mobile_payload = {
            loginType: "mobile",
            otpValue: otp,
            ABHANumber: state?.ABHANumber,
            loginValue: state?.phoneNumber,
          };
          setLoading(true);
          const enrolment_by_mobile_response = await verifyEnrolmentOtp(
            enrolment_by_mobile_payload
          );
          if (enrolment_by_mobile_response?.code === 1) {
            if (
              FAILED_REGEX.test(enrolment_by_mobile_response?.data?.authResult)
            ) {
              if (
                localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup" &&
                state?.type === "signup"
              ) {
                trackEvent(Events.ABHA_CREATION_ERROR, {
                  error_message: "Invalid OTP entered.",
                });
              }
              handleMobileResponse(enrolment_by_mobile_response);
            } else {
              localStorage.setItem(
                StorageKeys.LOGGED_IN_USER,
                encryptData(
                  enrolment_by_mobile_response?.data?.accounts?.[0]?.ABHANumber
                )
              );
              modalRef?.current?.show({
                header:
                  localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup" &&
                  state?.type === "signup"
                    ? state?.already_exists
                      ? "Your ABHA ID already exists"
                      : "ABHA ID created successfully"
                    : "Number Changed Successfully",
                subtitle:
                  localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup" &&
                  state?.type === "signup"
                    ? state?.already_exists
                      ? "Kindly download your latest ABHA card"
                      : "Your ABHA ID was created using Tatvacare"
                    : `Your ABHA linked mobile number has been changed to +91 ${state?.phoneNumber}`,
              });
              setTimeout(() => {
                navigate(`/profile`, {
                  state: {
                    phoneNumber: state?.phoneNumber,
                    email: state?.email,
                    aadhar: state?.aadhar,
                  },
                });
              }, 4000);
            }
          } else {
            if (
              localStorage.getItem(StorageKeys.LOGIN_FLOW) === "signup" &&
              state?.type === "signup"
            ) {
              trackEvent(Events.ABHA_CREATION_ERROR, {
                error_message:
                  enrolment_by_mobile_response?.data?.error?.message,
                error_code: enrolment_by_mobile_response?.data?.error?.code,
              });
            }
            handleMobileResponse(enrolment_by_mobile_response);
          }
          setLoading(false);
        } else {
          const profile_payload = {
            loginType: "mobile",
            otpValue: otp,
          };
          setLoading(true);
          const profile_response = await verifyProfileOtp(profile_payload);
          if (profile_response?.code === 1) {
            if (FAILED_REGEX.test(profile_response?.data?.authResult)) {
              handleMobileResponse(profile_response);
            } else {
              localStorage.setItem(StorageKeys.LOGIN_FLOW, "login");
              localStorage.setItem(
                StorageKeys.LOGGED_IN_USER,
                encryptData(profile_response?.data?.accounts?.[0]?.ABHANumber)
              );
              const verify_user_payload = {
                tToken: profile_response?.data?.token,
                ABHANumber: profile_response?.data?.accounts?.[0]?.ABHANumber,
              };

              const verify_user_response = await verifyUser(
                verify_user_payload
              );
              if (verify_user_response?.code === 1) {
                trackEvent(Events.ABHA_LOGIN_OTP_VERIFIED, {});
                const fetch_abha_profile_payload = {
                  xToken: verify_user_response?.data?.token,
                };

                const fetch_abha_profile_response = await fetchABHAProfiles(
                  fetch_abha_profile_payload
                );
                if (fetch_abha_profile_response?.code === 1) {
                  navigate(`/profile`, {
                    state: {
                      phoneNumber: state?.phoneNumber,
                    },
                  });
                } else {
                  logMoengageLoginErrorEvent(fetch_abha_profile_response);
                }
              } else {
                logMoengageLoginErrorEvent(verify_user_response);
              }
            }
          } else {
            logMoengageLoginErrorEvent(profile_response);
            handleMobileResponse(profile_response);
          }
          setLoading(false);
        }
        break;
      case "email":
        const enrolment_by_email_payload = {
          loginType: "email",
          otpValue: otp,
          ABHANumber: state?.ABHANumber,
          loginValue: state?.email,
        };
        setLoading(true);
        const enrolment_by_email_response = await verifyEnrolmentOtp(
          enrolment_by_email_payload
        );
        if (enrolment_by_email_response?.code === 1) {
          if (
            FAILED_REGEX.test(enrolment_by_email_response?.data?.authResult)
          ) {
            handleEmailResponse(enrolment_by_email_response);
          } else {
            modalRef?.current?.show({
              header: "Email Changed Successfully",
              subtitle: `Your ABHA linked email address has been changed to ${state?.email}`,
            });
            setTimeout(() => {
              navigate(`/profile`, {
                state: {
                  phoneNumber: state?.phoneNumber,
                  email: state?.email,
                  aadhar: state?.aadhar,
                },
              });
            }, 4000);
          }
        } else {
          handleEmailResponse(enrolment_by_email_response);
        }
        setLoading(false);
        break;
      case "aadhar":
        trackEvent(Events.ABHA_OTP_MOBILE_SUBMIT_CLICKED, {
          mobile_number: phoneNumber,
        });
        const enrolment_payload = {
          otpValue: otp,
          loginValue: phoneNumber,
          aadhar: state?.aadhar,
        };
        setLoading(true);
        const enrolment_response = await verifyEnrolmentByAadharOtp({
          payload: enrolment_payload,
          queryParams: JSON.parse(
            localStorage.getItem(StorageKeys.QUERY_PARAMS)
          ),
        });
        if (enrolment_response?.code === 1) {
          setUserAttributes(enrolment_response?.data);
          if (enrolment_response?.data?.isNew) {
            // New Address created
            trackEvent(Events.ABHA_CREATED_SUCCESSFULLY, {
              mobile_number: enrolment_response?.data?.ABHAProfile?.mobile,
              abha_id: enrolment_response?.data?.ABHAProfile?.ABHANumber,
              abha_address:
                enrolment_response?.data?.ABHAProfile?.phrAddress?.[0],
            });
          } else {
            // Already created
            trackEvent(Events.ABHA_ALREADY_EXISTS, {
              mobile_number: enrolment_response?.data?.ABHAProfile?.mobile,
              abha_id: enrolment_response?.data?.ABHAProfile?.ABHANumber,
              abha_address:
                enrolment_response?.data?.ABHAProfile?.phrAddress?.[0],
            });
          }
          localStorage.setItem(StorageKeys.LOGIN_FLOW, "signup");
          if (enrolment_response?.data?.ABHAProfile?.mobile === phoneNumber) {
            localStorage.setItem(
              StorageKeys.LOGGED_IN_USER,
              encryptData(enrolment_response?.data?.ABHAProfile?.ABHANumber)
            );
            //Same number as aadhar is used, no need for OTP verification
            modalRef?.current?.show({
              header: enrolment_response?.data?.isNew
                ? "ABHA ID created successfully"
                : "Your ABHA ID already exists",
              subtitle: enrolment_response?.data?.isNew
                ? "Your ABHA ID was created using Tatvacare"
                : "Kindly download your latest ABHA card",
            });
            setTimeout(() => {
              //navigate to profile screen after 2.5 seconds ( success modal close timer )
              navigate(`/profile`, {
                state: {
                  phoneNumber: enrolment_response?.data?.ABHAProfile?.mobile,
                  email: enrolment_response?.data?.ABHAProfile?.email,
                  aadhar: state?.aadhar,
                },
              });
            }, 4000);
          } else {
            //New number used, so require OTP verification
            const verify_enrolment_payload = {
              loginType: "mobile",
              loginValue: phoneNumber,
            };
            const verify_enrolment_response = await requestEnrolmentOtp(
              verify_enrolment_payload
            );
            if (verify_enrolment_response?.code === 1) {
              //Clear OTP input before navigation.
              setOtp("");
              navigate(`/otp?mode=number`, {
                state: {
                  message: verify_enrolment_response?.data?.message,
                  aadhar: state?.aadhar,
                  phoneNumber: phoneNumber,
                  ABHANumber: enrolment_response?.data?.ABHAProfile?.ABHANumber,
                  type: "signup",
                  already_exists: !verify_enrolment_response?.data?.isNew,
                },
              });
            } else {
              if (
                verify_enrolment_response?.data?.loginId === "Invalid LoginId"
              ) {
                showAlert("Entered input is invalid.");
              } else {
                showAlert(
                  verify_enrolment_response?.data?.error?.message ??
                    "Something went wrong"
                );
              }
            }
          }
        } else {
          trackEvent(Events.ABHA_CREATION_ERROR, {
            error_message: enrolment_response?.data?.error?.message,
            error_code: enrolment_response?.data?.error?.code,
          });
          handleAadharResponse(enrolment_response);
        }
        setLoading(false);
        break;
      default:
        break;
    }
  };

  return (
    <Container
      showBack
      onPressBack={() => {
        navigate(-1);
      }}
    >
      <div className="screen-container">
        <div>
          <Header1 color={Colors.TEXT_PRIMARY}>{getHeaderText()}</Header1>
          <BodyRegular2
            color={Colors.TEXT_SECONDARY}
            style={{ textAlign: "left" }}
          >
            {state?.message ?? ""}
          </BodyRegular2>
        </div>
        <div>{renderContent()}</div>
      </div>
      <div className="otp-btn-container">
        <Button
          buttonSize={"large"}
          disabled={
            otp.length < 6 || (mode === "aadhar" && phoneNumber.length < 10)
          }
          loading={loading}
          type={"primary"}
          width={"full"}
          customButtonStyle={{
            width: "calc(100dvw - 32px)",
            maxWidth: "448px",
            margin: "0px 16px",
          }}
          onPress={onPressContinue}
        />
      </div>
      <SuccessModal ref={modalRef} />
    </Container>
  );
};

export default OTPPage;
