import React from "react";
import moment from "moment";

import {
  BodyBold1,
  BodyRegular2,
  CaptionRegular1,
} from "../../styled/Typography";
import { Colors } from "../../../constants";

import "./index.css";

const AbhaCard = ({ abha_details }) => {
  return (
    <div className="AbhaCard-card-container">
      <div className="AbhaCard-card-header-container">
        <img
          src={require("../../../assets/images/nha_logo.png")}
          className="AbhaCard-nha-logo"
        />
        <img
          src={require("../../../assets/images/ayushman_logo.png")}
          className="AbhaCard-ayushman-logo"
        />
      </div>
      <div className="AbhaCard-card-body-container">
        <div className="AbhaCard-card-body-header-container">
          <img src={abha_details?.photo} className="AbhaCard-profile-picture" />
          <div className="AbhaCard-user-details-container">
            <div>
              <CaptionRegular1 color={Colors.TEXT_TERTIARY}>
                Name
              </CaptionRegular1>
              <BodyBold1 color={Colors.TEXT_PRIMARY}>
                {abha_details?.meta_data?.first_name}{" "}
                {abha_details?.meta_data?.middle_name}{" "}
                {abha_details?.meta_data?.last_name}
              </BodyBold1>
            </div>
            <div>
              <CaptionRegular1 color={Colors.TEXT_TERTIARY}>
                ABHA Number
              </CaptionRegular1>
              <BodyBold1 color={Colors.TEXT_PRIMARY}>
                {abha_details?.abha_number}
              </BodyBold1>
            </div>
          </div>
          {/* <img
            src={require("../../../assets/images/tatvacare_qr.png")}
            className="AbhaCard-qr"
          /> */}
        </div>
        <div className="AbhaCard-separator" />
        <div className="AbhaCard-card-body-footer-container">
          <div className="AbhaCard-card-body-footer-item-container">
            <CaptionRegular1 color={Colors.TEXT_TERTIARY}>
              Gender
            </CaptionRegular1>
            <BodyRegular2 color={Colors.TEXT_PRIMARY}>
              {abha_details?.meta_data?.gender === "M" ? "Male" : "Female"}
            </BodyRegular2>
          </div>
          <div className="AbhaCard-card-body-footer-item-container">
            <CaptionRegular1 color={Colors.TEXT_TERTIARY}>
              Date of Birth
            </CaptionRegular1>
            <BodyRegular2 color={Colors.TEXT_PRIMARY}>
              {moment(abha_details?.meta_data?.dob, "YYYY-MM-DD").format(
                "DD MMM YYYY"
              )}
            </BodyRegular2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbhaCard;
