import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Container, Input } from "../../components/atoms";
import { BodyRegular2, Header1 } from "../../components/styled/Typography";
import { Colors } from "../../constants";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import { requestEnrolmentOtp } from "../../apis";

import "./index.css";
import { useAlert } from "../../context/alert/AlertContext";

const UpdateInfoPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");

  const [phoneNumber, setPhoneNumber] = useState(state?.phoneNumber ?? "");
  const [email, setEmail] = useState(state?.email ?? "");

  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();

  const isBtnDisabled =
    mode === "number"
      ? phoneNumber.length < 10 || state?.phoneNumber === phoneNumber
      : email.length <= 0 ||
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ||
        state?.email === email;

  const getHeaderText = () => {
    switch (mode) {
      case "number":
        return (
          <Header1 color={Colors.TEXT_PRIMARY}>
            ABHA linked mobile Number
          </Header1>
        );
      case "email":
        return (
          <Header1 color={Colors.TEXT_PRIMARY}>
            ABHA linked email address
          </Header1>
        );
      default:
        return <></>;
    }
  };

  const getSubText = () => {
    switch (mode) {
      case "number":
        return (
          <BodyRegular2 color={Colors.TEXT_SECONDARY}>
            This mobile number will be used for all communications related to
            ABHA
          </BodyRegular2>
        );
      case "email":
        return (
          <BodyRegular2 color={Colors.TEXT_SECONDARY}>
            This email will be used for all communications related to ABHA
          </BodyRegular2>
        );
      default:
        return <></>;
    }
  };

  const renderInputField = () => {
    switch (mode) {
      case "number":
        return (
          <Input
            label={"Phone Number"}
            prefix={"+91"}
            leftIcon={<FontAwesomeIcon icon={faPhone} className="input-icon" />}
            autoFocus={true}
            inputProps={{
              placeholder: "Enter Phone Number",
              placeholdercolor: Colors.TEXT_TERTIARY,
              type: "tel",
              maxLength: 10,
              minLength: 10,
              value: phoneNumber,
              onChange: (e) => {
                if (/^-?\d*$/.test(e.target.value)) {
                  setPhoneNumber(e.target.value);
                }
              },
            }}
          />
        );
      case "email":
        return (
          <Input
            label={"Email"}
            autoFocus={true}
            inputProps={{
              placeholder: "Enter Email",
              placeholdercolor: Colors.TEXT_TERTIARY,
              type: "email",
              value: email,
              onChange: (e) => setEmail(e.target.value),
            }}
          />
        );
      default:
        return <></>;
    }
  };

  const handleResponse = (response) => {
    if (response?.data?.loginId === "Invalid LoginId") {
      showAlert("Entered input is invalid");
      return;
    }
    showAlert(response?.error?.message ?? "Something went wrong!");
    return;
  };

  const onPressContinue = async () => {
    switch (mode) {
      case "number":
        const number_payload = {
          loginType: "mobile",
          loginValue: phoneNumber,
        };
        setLoading(true);
        const number_response = await requestEnrolmentOtp(number_payload);
        if (number_response?.code === 1) {
          navigate(`/otp?mode=number`, {
            state: {
              message: number_response?.data?.message,
              aadhar: state?.aadhar,
              phoneNumber: phoneNumber,
              email: state?.email,
              ABHANumber: state?.ABHANumber,
            },
          });
        } else {
          handleResponse(number_response);
        }
        setLoading(false);
        break;
      case "email":
        const email_payload = {
          loginType: "email",
          loginValue: email,
        };

        setLoading(true);
        const email_response = await requestEnrolmentOtp(email_payload);
        if (email_response?.code === 1) {
          navigate(`/otp?mode=email`, {
            state: {
              message: email_response?.data?.message,
              aadhar: state?.aadhar,
              phoneNumber: state?.phoneNumber,
              email: email,
              ABHANumber: state?.ABHANumber,
            },
          });
        } else {
          handleResponse(email_response);
        }
        setLoading(false);
        break;
      default:
        break;
    }
  };

  return (
    <Container
      showBack
      onPressBack={() => {
        navigate(-1);
      }}
    >
      <div className="screen-container">
        <div>
          {getHeaderText()}
          {getSubText()}
        </div>
        {renderInputField()}
      </div>
      <div className="btn-container">
        <Button
          buttonSize={"large"}
          disabled={isBtnDisabled}
          loading={loading}
          type={"primary"}
          width={"full"}
          customButtonStyle={{
            width: "calc(100dvw - 32px)",
            maxWidth: "448px",
          }}
          onPress={onPressContinue}
        />
      </div>
    </Container>
  );
};

export default UpdateInfoPage;
