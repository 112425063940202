import React, { useState, forwardRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";

import { BodyRegular1, Header3 } from "../../styled/Typography";
import { Colors } from "../../../constants";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.css";
import Button from "../button";

const LogoutModal = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };

  // Expose show and hide methods to parent components via ref
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));

  // Render the popup
  return visible
    ? ReactDOM.createPortal(
        <div className="logout-modal-popup-overlay">
          <div className="logout-modal-popup-content">
            <div className="logout-header-container">
              <Header3 color={Colors.TEXT_PRIMARY}>Confirmation</Header3>
            </div>
            <BodyRegular1 color={Colors.TEXT_SECONDARY}>
              Are you sure you want to logout ?
            </BodyRegular1>
            <div className="logout-modal-btn-container">
              <Button
                buttonSize={"small"}
                disabled={false}
                type={"secondary"}
                width={"full"}
                title="No"
                onPress={hide}
                customButtonStyle={{ flex: 1 }}
              />
              <Button
                buttonSize={"small"}
                disabled={false}
                type={"primary"}
                width={"full"}
                title="Yes"
                onPress={() => {
                  hide();
                  props.onPressYes();
                }}
                customButtonStyle={{ flex: 1 }}
              />
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
});

export default LogoutModal;
