import CryptoJS from "crypto-js";
import { Methods, StorageKeys, avoidTxnIdEndpoints } from "../constants";
import { storeXTokenEndpoints } from "../constants/api";

const TIMEOUT_MS = 10000;
const {
  REACT_APP_ENC_KEY,
  REACT_APP_ENC_IV,
  REACT_APP_API_KEY,
  REACT_APP_BASE_URL,
} = process.env;

export const encryptData = (data) => {
  try {
    var truncHexKey = CryptoJS.SHA256(REACT_APP_ENC_KEY ?? "")
      .toString()
      .substr(0, 32);
    var key = CryptoJS.enc.Utf8.parse(truncHexKey);
    var iv = CryptoJS.enc.Utf8.parse(REACT_APP_ENC_IV ?? "");
    var ciphertext = CryptoJS.AES.encrypt(
      typeof data === "string" ? data : JSON.stringify(data),
      key,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }
    );
    return ciphertext.toString();
  } catch (error) {
    console.log("[ENCRYPTION ERROR]: ", error);
  }
};

export const decryptData = (encryptedData) => {
  try {
    var truncHexKey = CryptoJS.SHA256(REACT_APP_ENC_KEY ?? "")
      .toString()
      .substr(0, 32);
    var key = CryptoJS.enc.Utf8.parse(truncHexKey);
    var iv = CryptoJS.enc.Utf8.parse(REACT_APP_ENC_IV ?? "");
    var decryptedData = CryptoJS.AES.decrypt(encryptedData, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return decryptedData.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.log("[DECRYPTION ERROR]: ", error);
  }
};

const appendQueryParams = (url, queryParams) => {
  if (!queryParams || Object.keys(queryParams).length === 0) {
    return url;
  }

  const queryString = Object.keys(queryParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join("&");

  if (url.includes("?")) {
    return `${url}&${queryString}`;
  } else {
    return `${url}?${queryString}`;
  }
};

const handleResponse = (res, storeXToken = false) => {
  if (res?.code === 1) {
    //Only process the response if the code is 1 ( success )
    if (res?.data?.txnId) {
      //Update transaction ID in local storage
      localStorage.setItem(
        StorageKeys.TRANSACTION_ID,
        encryptData(res?.data?.txnId)
      );
    }

    if (storeXToken) {
      localStorage.setItem(StorageKeys.X_TOKEN, res?.data?.tokens?.token);
    }
  }

  return res;
};

const request = async ({
  method = Methods.GET,
  baseURL = REACT_APP_BASE_URL,
  url,
  queryParams = {},
  headers = {},
  data = {},
}) => {
  const api_url = appendQueryParams(`${baseURL}${url}`, queryParams);
  const api_headers = {
    "api-key": REACT_APP_API_KEY,
    ...headers,
  };

  const ACCESS_TOKEN = localStorage.getItem(StorageKeys.ACCESS_TOKEN) ?? null;
  const TRANSACTION_ID =
    localStorage.getItem(StorageKeys.TRANSACTION_ID) ?? null;

  const payload = {
    ...data,
    ...(ACCESS_TOKEN && { accessToken: decryptData(ACCESS_TOKEN) }), //Adding access token if available
    ...(TRANSACTION_ID &&
      !avoidTxnIdEndpoints.includes(url) && {
        lastResponseTxnId: decryptData(TRANSACTION_ID),
      }), //Adding transaction ID is available
  };

  const controller = new AbortController();
  const signal = controller.signal;

  const timeoutId = setTimeout(() => {
    controller.abort("TIMEOUT");
  }, TIMEOUT_MS);

  try {
    let response = await fetch(api_url, {
      method,
      headers: api_headers,
      ...([Methods.POST, Methods.PUT, Methods.PATCH].includes(method) && {
        body: encryptData(payload),
      }),
      signal: signal,
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      console.log(`HTTP error! Status: ${response.status}`);
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    response = await response.json();
    let decryptedResponse = decryptData(response);
    console.log(api_url, " ===> ", decryptedResponse);
    decryptedResponse = handleResponse(
      JSON.parse(decryptedResponse),
      storeXTokenEndpoints.includes(url)
    );
    return decryptedResponse;
  } catch (error) {
    console.log("[API ERROR]: ", error);
    throw error;
  }
};

export default request;
