import React, { createContext, useState, useContext } from "react";

import "./index.css";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [autoHideTimer, setAutoHideTimer] = useState(null);

  const showAlert = (msg, autoHideDuration = 7000) => {
    setMessage(msg);
    setVisible(true);

    setAutoHideTimer(
      setTimeout(() => {
        hideAlert();
      }, autoHideDuration)
    );
  };

  const hideAlert = () => {
    setVisible(false);
    setMessage("");
    if (autoHideTimer) {
      clearTimeout(autoHideTimer);
    }
  };

  return (
    <AlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      <div className={`alert-container ${visible ? "slide-in" : "slide-out"}`}>
        {visible && (
          <div className="alert">
            <div className="alert-content">{message}</div>
          </div>
        )}
      </div>
    </AlertContext.Provider>
  );
};
