import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { Header3 } from "../../styled/Typography";
import { useSession } from "../../../context/session/SessionContext";

import "./index.css";

const Container = ({ showBack = true, onPressBack = () => {}, children }) => {
  const { isActive } = useSession();

  return (
    <div className="container">
      {showBack && (
        <div
          className="back-container"
          style={{ top: isActive ? "40px" : "0px" }}
        >
          <div className="back-arrow" onClick={onPressBack}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <Header3 color={"#1D1B20"}>Back</Header3>
        </div>
      )}
      <div
        className={`children-container`}
        style={{ marginTop: isActive ? "88px" : showBack ? "48px" : "0px" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
